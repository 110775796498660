import request from '@/plugins/axios';

/**
 * 夺标订单信息
 */
export function getGroupGoodsOrderInfo(params) {
  return request({
    url: `shop-mobile/groupOrder/getGroupInfo/${params.goodsId}`,
    method: 'post',
    params,
  });
}

/**
 * 夺标商品
 */
export function getWinTheBid(data) {
  return request.get('shop-mobile/goods/groupInfo/page', data, {
    login: false,
  })
}
/**
 * 夺标商品详情(通过id查询)
 */
export function getWinTheBidDetails(data) {
  return request.get('shop-mobile/goods/groupInfo/' + data.id + '?refereeId=' + data.refereeId,{}, {
    login: false,
  })
}
/**
 * 确认下单
 */
export function groupConfirmation(data) {
  return request.post('shop-mobile/groupOrder/saveOrder',data, {
    login: false,
  })
}
