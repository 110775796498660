import request from '@/plugins/axios';

/**
 * 夺标商品分页查询
 */
export function getPageAllGroupGoods(params = {}) {
  return request({
    url: 'shop-mobile/goods/groupInfo/page',
    method: 'get',
    params
  });
}

/**
 * 夺标商品详情
 *url/id?refereeId=
 */
export function getGroupGoodsInfoByIdAndRefereeId(params) {
  return request({
    url: `shop-mobile/goods/groupInfo/${params.id}`,
    method: 'get',
    params
  });
}

/**
 * 拼团下单页面
 */
export function groupOrder(params) {
  return request({
    url: `shop-mobile/groupOrder/getGroupInfo/${params.id}`,
    method: 'get',
    params
  });

  // return request.post('shop-mobile/groupOrder/getGroupInfo/' + data.groupInfoId + '?betweenId=' + data.betweenId + '&refereeId=' + data.refereeId, {}, {
  //   login: false,
  // });
}

/**
 * 确认下单
 */
export function groupSaveOrder(data) {
  return request({
    url: `shop-mobile/groupOrder/saveOrder`,
    method: 'post',
    data
  });
}
